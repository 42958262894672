<template>
    <v-container
        class="app-case-card pa-4 bg-surface mb-4"
        variant="outlined"
        data-testid="app-case-card"
    >
        <v-row class="w-100 align-start" no-gutters>
            <v-row no-gutters>
                <case-card-badge :case-status="status" />
                <case-status-tooltip :case-status="status" />
                <div class="app-card-content d-flex flex-column justify-start">
                    <h4 class="text-secondary-text" data-testid="case-card-title">{{ title }}</h4>
                    <div class="text-secondary text-subtitle-1">
                        {{ description }}
                    </div>
                </div>
            </v-row>
            <v-spacer />
            <div class="app-card-actions justify-end">
                <div class="app-card-action-item d-flex flex-column mr-5 align-center">
                    <app-menu-item
                        background="light"
                        icon="settings"
                        :to="action(ROUTES.EDIT_CASE)"
                        :disabled="!action(ROUTES.EDIT_CASE)"
                    />
                    <span class="text-subtitle-2 text-dark-blue">Settings</span>
                </div>
                <div class="app-card-action-item d-flex flex-column mr-5 align-center">
                    <app-menu-item
                        background="light"
                        icon="dicoms"
                        :to="action(ROUTES.CT_SCAN)"
                        :disabled="!action(ROUTES.CT_SCAN)"
                    />
                    <span class="text-subtitle-2 text-dark-blue">Dicoms</span>
                </div>
                <div class="app-card-action-item d-flex flex-column mr-5 align-center">
                    <app-menu-item
                        background="light"
                        icon="planner"
                        :to="action(ROUTES.PLANNER)"
                        :disabled="!action(ROUTES.PLANNER)"
                        data-testid="card-planner-icon"
                    />
                    <span class="text-subtitle-2 text-dark-blue">3D Page</span>
                </div>
                <div class="app-card-action-item d-flex flex-column mr-5 align-center">
                    <app-menu-item
                        background="light"
                        icon="plans"
                        :to="action(ROUTES.PLANS)"
                        :disabled="!action(ROUTES.PLANS)"
                    />
                    <span class="text-subtitle-2 text-dark-blue">Reports</span>
                </div>
            </div>
        </v-row>
        <v-row class="w-100" no-gutters>
            <div class="w-100 d-flex ga-4">
                <case-card-content label="Patient" :text="patient" v-if="patient">
                    {{ patient }}
                </case-card-content>
                <case-card-content label="Surgeon" :text="surgeon" v-if="surgeon">
                    {{ surgeon }}
                </case-card-content>
                <case-card-content label="Owner" :text="owner" v-if="owner">
                    {{ owner }}
                </case-card-content>
                <case-card-content label="Created" :text="createdAt">
                    {{ createdAt }}
                </case-card-content>
                <case-card-content label="Formus Reference" :text="reference">
                    {{ reference }}
                </case-card-content>
            </div>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import CaseCardContent from '@/dashboard/CaseCardContent.vue';
    import CaseCardBadge from '@/dashboard/CaseCardBadge.vue';
    import AppMenuItem from '@/components/AppMenuItem.vue';
    import { ROUTES } from '@/router';
    import type { CaseStatus } from '@/api/caseSearch';

    import { enabledActionsByStatus } from '@/app/enabledActionsByStatus';
    import CaseStatusTooltip from '@/dashboard/CaseStatusTooltip.vue';

    export interface Props {
        title: string;
        description?: string;
        reference: string;
        createdAt: string;
        owner?: string;
        status: CaseStatus;
        surgeon?: string;
        patient?: string;
        id?: string;
    }

    const props = defineProps<Props>();

    function action(route: ROUTES) {
        if (enabledActionsByStatus(props.status).includes(route)) {
            return {
                name: route,
                params: {
                    id: props.id,
                },
            };
        }
    }
</script>

<style scoped lang="scss">
    .app-case-card {
        border-radius: 4px;
        border: 1px solid rgb(var(--v-theme-grey-light-1));
        margin-bottom: 20px;
        max-width: 1020px;
    }

    .app-card-content {
        //height: 76px;
        max-width: 500px;
    }

    .app-card-content h4 {
        font-size: 21px;
        line-height: 34px;
        font-weight: 400;
    }

    .app-card-actions {
        display: flex;
        align-items: flex-start;
    }

    .app-card-action-item {
        width: 60px;
    }
</style>
