import type {ApiLinks} from '@/api/links';
import type {Url} from '@/formus/types';
import {type AxiosRequestConfig, HttpStatusCode} from 'axios';
import {client} from '@/api/http';
import {errorDetail} from '@/planner/api/errorDetail';
import type {CupAnteversionMode, CupFitMethod, OptimisationTargets, StemSelector} from '@/formus/specification';
import type {AlignmentMode} from '@/formus/anatomy/pelvis/alignment';
import {type ApiPreferences, getPreferences} from '@/planner/api/preferences';
import {type ConvertedRepresentation, convertRepresentation,} from '@/planner/api/convertRepresentation';
import {alignmentMode, type ApiAlignmentMode} from '@/planner/api/alignmentMode';
import type {StemSystem} from '@/formus/catalog/stem';
import {taggedLogger} from '@/util';

const logger = taggedLogger('complete-specification');

export type SpecificationProperties = {
    created: string;
    updated: string;
    stem: {
        selector: StemSelector[];
    };
    cup: {
        fit_method: CupFitMethod;
        abduction_angle: number;
        anteversion_mode: CupAnteversionMode | null;
        anteversion_angle: number | null;
    };
    target: OptimisationTargets;
    alignment_mode: AlignmentMode | null;
    preferred_system: StemSystem;
};

const specificationLinkMap = {
    self: 'self',
    case: 'up',
    canonical: 'canonical',
    previous: {rel: 'previous', optional: true},
    preferences: 'preferences',
    history: 'history',
} as const;

/** Identifies a surgical-specification on the API */
export type ApiSpecificationId = { case: number; specification: number };

export type ApiSpecification = ConvertedRepresentation<
    SpecificationProperties,
    typeof specificationLinkMap
> & {
    id: ApiSpecificationId;
};

type ApiSpecificationResponse = SpecificationProperties &
    ApiLinks & { alignment_mode: ApiAlignmentMode };

export async function getSpecification(
    specId: ApiSpecificationId | Url,
    config?: AxiosRequestConfig,
): Promise<ApiSpecification> {
    let id, url;
    if (typeof specId === 'object') {
        id = specId;
        url = `project/${id.case}/hip/surgical/specification/${id.specification}`;
    } else {
        url = specId;
        id = specificationIdFromUrl(url);
    }

    const {status, data} = await client.get<ApiSpecificationResponse>(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, specificationLinkMap),
            alignmentMode: data.alignment_mode ? alignmentMode(data.alignment_mode) : null,
        };
    }

    throw Error(`Failed to load study from ${url}` + errorDetail(data));
}

export function specificationIdFromUrl(url: Url): ApiSpecificationId {
    const match = url.match(/project\/([0-9]*)\/hip\/surgical\/specification\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            specification: Number(match[2]),
        };
    }
    throw Error(`Failed to extract specification-id from url '${url}'`);
}

export type FullSpecificationProperties = SpecificationProperties & {
    cup: {
        fit_method: CupFitMethod;
        abduction_angle: number;
        anteversion_mode: CupAnteversionMode;
        anteversion_angle: number;
    };
    target: OptimisationTargets;
    alignment_mode: AlignmentMode;
};

/**
 * A surgical-specification that has any 'default' (null) properties filled from the associated surgical-preferences
 */
export type ApiCompleteSpecification = ConvertedRepresentation<
    FullSpecificationProperties,
    typeof specificationLinkMap
> & { id: ApiSpecificationId };

export async function getCompleteSpecification(
    specId: ApiSpecificationId | Url,
    config?: AxiosRequestConfig,
): Promise<ApiCompleteSpecification> {
    const specification = await getSpecification(specId, config);
    const preferences = await getPreferences(specification.preferences, config);
    return createCompleteSpecification(specification, preferences);
}

export function mergeCupSettings(
    specificationCup: ApiSpecification["cup"],
    preferencesCup: ApiPreferences["cup"]
): ApiCompleteSpecification["cup"] {
    return {
        fit_method: specificationCup.fit_method ?? preferencesCup.fit_method,
        abduction_angle: specificationCup.abduction_angle ?? preferencesCup.abduction_angle,
        anteversion_mode: specificationCup.anteversion_mode ?? preferencesCup.anteversion_mode,
        anteversion_angle: specificationCup.anteversion_angle ?? preferencesCup.anteversion_angle,
    };
}

export function resolveTargets(
    specificationTarget: OptimisationTargets
): OptimisationTargets {
    return {
        leg_length_change: specificationTarget.leg_length_change ?? 0,
        offset_change: specificationTarget.offset_change ?? 0,
    };
}

export function resolveAlignmentMode(
    specificationAlignmentMode: AlignmentMode | null,
    preferencesAlignMode: AlignmentMode | null
): AlignmentMode {
    return specificationAlignmentMode ?? preferencesAlignMode ?? "CT";
}

export function resolvePreferredSystem(
    specificationPreferredSystem: StemSystem,
    preferencesPreferredSystem: StemSystem
): StemSystem {
    return specificationPreferredSystem ?? preferencesPreferredSystem ?? "taperloc-complete";
}

export function createCompleteSpecification(
    specification: ApiSpecification,
    preferences: ApiPreferences,
): ApiCompleteSpecification {

    if (!specification.preferredSystem) {
        logger.warn(
            'No preferred system found in specification, using default. ' +
            'This should be set on the API. Maybe a cache issue.',
        );
    }

    // Build the complete specification by merging parts individually
    const cup = mergeCupSettings(specification.cup, preferences.cup);
    const target = resolveTargets(specification.target);
    const alignmentMode = resolveAlignmentMode(specification.alignmentMode, preferences.alignMode);
    const preferredSystem = resolvePreferredSystem(specification.preferredSystem, preferences.preferredSystem);

    return {...specification, cup, target, alignmentMode, preferredSystem};
}
