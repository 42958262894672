import type {Measurement} from '@/planner/measurements';
import {formatLengthSign} from '@/lib/format/formatLengthSign';
import type {FemurDiceScores} from '@/planner/plannerState.ts';
/*
    This is overkill for now as we are just validating the one measurement but sets up a way to extend this to
    the rest of the measurements when we decide to
*/

// The dice score threshold that was determined from the HLD discovery work
const DICE_THRESHOLD=0.8

export type MeasurementKey =
    | 'hip-length-difference'
    | 'femur-anteversion'
    | 'acetabular-anteversion'
    | 'acetabular-inclination';

export type MeasurementInfo = Measurement & {
    title: string;
    format: (value: number | null) => string;
};

export function createMeasurement(
    title: string,
    format: (value: number | null) => string,
    data: Partial<Measurement> | number | null = {},
): MeasurementInfo {
    if (typeof data === 'number') {
        // If `data` is a number, treat it as the `value`
        return {
            title,
            format,
            value: data,       // Use the numeric data as `value`
            preCheck: false,   // Default to no pre-check
            warning: false,    // Default to no warning
        };
    }

    // Handle object or null input with metadata
    return {
        title,
        format,
        value: data?.value ?? null,
        preCheck: data?.preCheck ?? false,
        warning: data?.warning ?? false,
    };
}

export const createHipLengthDifferenceMeasurement = (
    hipLengthDifference: number | null,
    diceScores: FemurDiceScores | null): MeasurementInfo => {

    if (diceScores === null){
        return createMeasurement('Hip Length Difference', formatLengthSign,
        hipLengthDifference === null ? null
            : {
                preCheck: true,
                warning: true,
                value: hipLengthDifference,
            }
        );
    }

    return createMeasurement('Hip Length Difference', formatLengthSign,
        hipLengthDifference === null ? null
            : {
                preCheck: false,
                warning: hasDiceScoreWarning(diceScores),
                value: hipLengthDifference,
            }
    );
}

const hasDiceScoreWarning = (
    diceScores: { operativeFemurDiceScore: number | null; contralateralFemurDiceScore: number | null }): boolean => {

    const { operativeFemurDiceScore, contralateralFemurDiceScore } = diceScores;

    return (
        operativeFemurDiceScore !== null &&
        contralateralFemurDiceScore !== null &&
        (operativeFemurDiceScore < DICE_THRESHOLD || contralateralFemurDiceScore < DICE_THRESHOLD)
    );
}