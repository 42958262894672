import { client } from '@/api/http';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { errorDetail } from '@/planner/api/errorDetail';
import type { FittingGroup } from '@/formus/anatomy/fitting';
import { type ConvertedRepresentation, convertRepresentation} from '@/planner/api/convertRepresentation';

type FittingData = {
    fit: FittingGroup[];
};

const fittingLinkMap = {
    self: 'self',
    case: 'up',
} as const;

/** Identifies a set of study-fitting on the API */
export type ApiStudyFittingId = { case: number; fit_results: number };

export type ApiStudyFitting = ConvertedRepresentation<FittingData, typeof fittingLinkMap>& { id: ApiStudyFittingId };

/**
 * Fetches fitting data for a study using the fitting endpoint.
 */
export async function getFitting(
    fittingId: ApiStudyFittingId | Url, config?: AxiosRequestConfig,): Promise<ApiStudyFitting> {
    let id, url;
    if (typeof fittingId === 'object') {
        id = fittingId;
        url = `project/${id.case}/fit/${id.fit_results}/`;
    } else {
        url = fittingId;
        id = fittingIdFromUrl(url);
    }

    const { status, data } = await client.get<FittingData & ApiLinks>(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, fittingLinkMap),
        };
    }

    throw new Error(`Failed to load fitting from ${url}. ${errorDetail(data)}`);
}

/**
 * Extracts the fitting ID from a URL.
 */
export function fittingIdFromUrl(url: Url): ApiStudyFittingId {
    const match = url.match(/project\/([0-9]*)\/fit\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            fit_results: Number(match[2]),
        };
    }
    throw Error(`Failed to extract fitting-id from url '${url}'`);
}