export type FittingMeasurements = {
    mahalanobis_distance?: number;
    rms_error?: number;
    dice_score?: number;
};

export type FittingGroupName = (typeof fitGroups)[number];

export type FittingGroup = {
    /** Name of the fitting group */
    name: FittingGroupName;

    /** PC fit mesh */
    principle_component?: FittingMeasurements;

    /** RBF fit mesh */
    radial_basis_function?: FittingMeasurements;
};

/**
 * Finds a specific fitting item within a group by its name.
 * Throws errors if no match is found or there are multiple matches.
 */
export function findDiceScore(
    fitResults: FittingGroup[],
    name: string,
): number | null {
    // Attempt to find a fitting group by name
    const fitting = fitResults.find((item) => item.name === name);

    // If a match is found, extract the Dice score (if available), otherwise return null
    return fitting?.principle_component?.dice_score ?? null;
}

const fitGroups = [
    'operative-femur',
    'contralateral-femur',
    'operative-hemipelvis',
    'contralateral-hemipelvis'] as const;
