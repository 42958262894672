import { client } from '@/api/http';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { errorDetail } from '@/planner/api/errorDetail';
import type { LandmarkGroup } from '@/formus/anatomy/landmarks';
import {
    type ConvertedRepresentation,
    convertRepresentation,
} from '@/planner/api/convertRepresentation';

type LandmarksProperties = {
    groups: LandmarkGroup[];
};

const landmarksLinkMap = {
    self: 'self',
    case: 'up',
} as const;

/** Identifies a set of study-landmarks on the API */
export type ApiStudyLandmarksId = { case: number; landmarks: number };

export type ApiStudyLandmarks = ConvertedRepresentation<
    LandmarksProperties,
    typeof landmarksLinkMap
> & {
    id: ApiStudyLandmarksId;
};

export async function getLandmarks(
    landmarksId: ApiStudyLandmarksId | Url,
    config?: AxiosRequestConfig,
): Promise<ApiStudyLandmarks> {
    let id, url;
    if (typeof landmarksId === 'object') {
        id = landmarksId;
        url = `project/${id.case}/landmark/${id.landmarks}/`;
    } else {
        url = landmarksId;
        id = landmarksIdFromUrl(url);
    }

    const { status, data } = await client.get<LandmarksProperties & ApiLinks>(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, landmarksLinkMap),
        };
    }

    throw Error(`Failed to load landmarks from ${url}` + errorDetail(data));
}

export function landmarksIdFromUrl(url: Url): ApiStudyLandmarksId {
    const match = url.match(/project\/([0-9]*)\/landmark\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            landmarks: Number(match[2]),
        };
    }
    throw Error(`Failed to extract landmarks-id from url '${url}'`);
}
