<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left">Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <CaseSettingsTopNotifications />

            <app-form-title>
                {{ pageTitle }}
            </app-form-title>

            <app-main-card class="pa-8">
                <app-form-section-title class="mb-8">Case Information</app-form-section-title>

                <v-container class="px-10 pt-0">
                    <app-form-group label="Case Name" input-id="case-name-input">
                        <app-text-input
                            v-model="store.case.surgery.name"
                            data-test-id="case-name-input"
                            input-id="case-name-input"
                            :disabled="store.isCaseReadonly"
                            :error-messages="
                                showError('surgeryName') ? 'Please provide a case name' : ''
                            "
                        />
                    </app-form-group>

                    <app-form-group label="Description" optional input-id="case-name-description">
                        <app-textarea
                            v-model="store.case.surgery.description"
                            :disabled="store.isCaseReadonly"
                            input-id="case-name-description"
                            maxlength="255">

                        </app-textarea>
                    </app-form-group>

                    <app-form-group label="Date of Surgery" optional input-id="case-surgery-date">
                        <app-text-input
                            type="date"
                            v-model="store.case.surgery.date"
                            :disabled="store.isCaseReadonly"
                            input-id="case-surgery-date"
                            :error-messages="
                                showError('surgeryDate') ? 'Please provide a value date' : ''
                            "
                        />
                    </app-form-group>

                    <app-form-group label="Procedure" input-id="case-surgery-procedure">
                        <app-text-input
                            variant="outlined"
                            density="compact"
                            disabled
                            v-model="store.case.surgery.procedure"
                            input-id="case-surgery-procedure"
                        />
                    </app-form-group>

                    <app-form-group label="Implant Side">
                        <app-toggle
                            v-model="store.case.surgery.side"
                            :disabled="!routeStore.isNewCase || store.isCaseReadonly"
                            :error-messages="
                                showError('surgerySide')
                                    ? 'Please provide patient implant side'
                                    : ''
                            "
                            :options="[
                                { label: 'Left', value: 'left' },
                                { label: 'Right', value: 'right' },
                            ]"
                        />
                    </app-form-group>
                </v-container>
            </app-main-card>
            <app-main-card class="pa-8">
                <app-form-section-title class="mb-2">Patient Information</app-form-section-title>
                <app-form-subtitle>
                    Patient details are not able to be changed once scans have been uploaded. Please
                    ensure all details are correct before uploading DICOM files for this case.
                </app-form-subtitle>

                <v-container class="px-10 pt-0">
                    <app-form-group label="First Name" input-id="patient-first-name-input">
                        <app-text-input
                            input-id="patient-first-name-input"
                            v-model="store.case.patient.firstName"
                            :disabled="store.hasStudy || store.isCaseReadonly"
                            :error-messages="
                                showError('patientFirstName')
                                    ? 'Please provide patient first name'
                                    : ''
                            "
                        />
                    </app-form-group>

                    <app-form-group
                        label="Middle Name(s)"
                        optional
                        input-id="patient-middle-names-input"
                    >
                        <app-text-input
                            input-id="patient-middle-names-input"
                            v-model="store.case.patient.middleNames"
                            :disabled="store.hasStudy || store.isCaseReadonly"
                        />
                    </app-form-group>

                    <app-form-group label="Family Name" input-id="patient-family-name-input">
                        <app-text-input
                            input-id="patient-family-name-input"
                            v-model="store.case.patient.familyName"
                            :disabled="store.hasStudy || store.isCaseReadonly"
                            :error-messages="
                                showError('patientFamilyName')
                                    ? 'Please provide patient family name'
                                    : ''
                            "
                        />
                    </app-form-group>

                    <app-form-group label="Sex">
                        <app-toggle
                            v-model="store.case.patient.sex"
                            :disabled="store.hasStudy || store.isCaseReadonly"
                            :error-messages="
                                showError('patientSex') ? 'Please provide patient sex' : ''
                            "
                            :options="[
                                { label: 'Male', value: 'male' },
                                { label: 'Female', value: 'female' },
                            ]"
                        />
                    </app-form-group>

                    <app-form-group label="Date of Birth" input-id="patient-dob-input">
                        <app-text-input
                            type="date"
                            input-id="patient-dob-input"
                            v-model="store.case.patient.dateOfBirth"
                            :disabled="store.hasStudy || store.isCaseReadonly"
                            :error-messages="
                                showError('patientDateOfBirth')
                                    ? 'Please provide patient date of birth'
                                    : ''
                            "
                        />
                    </app-form-group>
                </v-container>
            </app-main-card>
            <app-main-card class="pa-8">
                <app-form-section-title class="mb-8"
                    >Surgeon and Surgical Specifications
                </app-form-section-title>

                <v-container class="px-10 pt-0">
                    <CaseSettingsSurgeonAndSpecifications />

                    <div v-if="spinopelvicStore.isEnabled">
                        <SpinopelvicSettings></SpinopelvicSettings>
                    </div>
                    <CaseSettingsSavedNotification />
                    <app-form-actions>
                        <app-form-action-notification
                            message="You have unsaved changes on this page"
                            v-if="store.isDirty"
                        />
                        <app-button-primary
                            :disabled="
                                !store.isDirty || store.isSaving || store.isCaseDeemedUnsuitable
                            "
                            @click="saveThenReroute(router)"
                        >
                            Save Case Settings
                        </app-button-primary>
                    </app-form-actions>
                </v-container>
            </app-main-card>
            <app-main-card class="pa-8">

                <div ref="upload">
                    <CaseSettingsCTScan v-if="!routeStore.isNewCase" />
                </div>

                <UnassignSurgeon v-if="unassignSurgeonFeatureFlag" />
            </app-main-card>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import { ROUTES } from '@/router';
    import { useCaseSettings } from '@/caseSettings/store/store';
    import { useVersion } from '@/app/version';
    import { type Router, useRoute, useRouter } from 'vue-router';
    import SpinopelvicSettings from '@/caseSettings/SpinopelvicSettings.vue';
    import CaseSettingsSavedNotification from '@/caseSettings/CaseSettingsSavedNotification.vue';
    import UnassignSurgeon from '@/caseSettings/UnassignSurgeon.vue';
    import { computed, ref } from 'vue';
    import { useSpinopelvic } from '@/spinopelvic/store/store';
    import { useRouteStore } from '@/caseSettings/routeStore';
    import { verify } from '@/lib/verify';
    import CaseSettingsSurgeonAndSpecifications from '@/caseSettings/CaseSettingsSurgeonAndSpecifications.vue';
    import CaseSettingsCTScan from '@/caseSettings/CaseSettingsCTScan.vue';
    import { useCaseUpload } from '@/caseSettings/case-upload/store';
    import CaseSettingsTopNotifications from '@/caseSettings/CaseSettingsTopNotifications.vue';

    const versionStore = useVersion();
    const store = useCaseSettings();
    const spinopelvicStore = useSpinopelvic();

    const router = useRouter();
    const routeStore = useRouteStore();
    const uploadStore = useCaseUpload();

    store.$reset();

    uploadStore.$reset();
    spinopelvicStore.$reset();

    await versionStore.load();
    const route = useRoute();

    const upload = ref<null | HTMLElement>(null);

    /**
     * FL-1690 will be hidden on the first release of vue 3.
     * The feature is not a key feature and will require some changes.
     * - We suggest renaming 'Unassign surgeon' as 'Mark as unsuitable':
     * - Add a column in the db for cases marked as unsuitable. With this flag we can hide the case from surgeons
     * - have a new icon/status for cases unsuitable on the dashboard list
     * - take different actions as disabling the download pdf, etc, based on this flag
     */
    const unassignSurgeonFeatureFlag = false;

    const isNewCase = routeStore.isNewCase;
    if (isNewCase) {
        await store.loadNewCase();
    } else {
        const caseIdParam = verify(route.params.id, 'case id is required');
        const caseId = Number(caseIdParam);

        await store.loadExistingCase(caseId);
        await useSpinopelvic().load(caseId);
    }

    async function saveThenReroute(router: Router) {
        const isNewCase = routeStore.isNewCase;

        // This is a hack to show the saved notification
        // At the moment we only show one notification at a time.
        // Either the saved notification or the upload notification
        uploadStore.hasUploaded = false;

        await store.save(isNewCase, versionStore.webComponentVersion);

        if (!store.isError && isNewCase) {
            const failure = await router.push({
                name: ROUTES.EDIT_CASE,
                params: {
                    id: store.case.id,
                },
            });

            if (failure) {
                // navigation prevented
            } else {
                // in theory nextTick should work, but it does not.
                setTimeout(() => {
                    const scanUpload = upload.value;
                    if (scanUpload) {
                        scanUpload.scrollIntoView({ behavior: 'auto' });
                    }
                }, 0);
            }
        }
    }

    function showError(field: string): boolean {
        if (!store.displayErrors) return false;

        return store.hasFieldError(field);
    }

    const pageTitle = computed(() => {
        return isNewCase ? 'New Formus Case' : 'Case Settings';
    });
</script>

<style scoped lang="scss">
    :deep(.v-field.v-field--variant-outlined) {
        border-radius: 4px !important;
    }
</style>
