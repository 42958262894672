<template>
    <v-dialog class="dialog-wrapper">
        <template v-slot:activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps"></slot>
        </template>

        <slot></slot>
    </v-dialog>
</template>

<style scoped lang="scss">
    .dialog-wrapper :deep(.v-overlay__content),
    .dialog-wrapper :deep(.v-overlay__content .v-card) {
        border-radius: 4px;
        padding-top: 20px;
        padding-bottom: 50px;
    }
</style>
