import stemImageUrl from '@/assets/icons/stem-panel.svg';
import stemImageHoverUrl from '@/assets/icons/stem-panel-hover.svg';
import cupImageUrl from '@/assets/icons/cup-panel.svg';
import cupImageHoverUrl from '@/assets/icons/cup-panel-hover.svg';
import combinedImageUrl from '@/assets/icons/combined-panel.svg';
import combinedImageHoverUrl from '@/assets/icons/combined-panel-hover.svg';
import expandLateral from '@/assets/icons/expand-lateral.svg';
import collapseLateral from '@/assets/icons/collapse-lateral.svg';
import megaphone from '@/assets/icons/megaphone.svg';
import caseNew from '@/assets/icons/case-new.svg';
import caseProcessing from '@/assets/icons/case-processing.svg';
import caseCompleted from '@/assets/icons/case-completed.svg';
import caseError from '@/assets/icons/case-error.svg';
import completeTooltip from '@/assets/icons/complete-tooltip.svg';
import errorTooltip from '@/assets/icons/error-tooltip.svg';
import infoTooltip from '@/assets/icons/info-tooltip.svg';
import navigationSettings from '@/assets/icons/navigation-settings-outline.svg';
import navigationSettingsWhite from '@/assets/icons/navigation-settings-outline-white.svg';
import navigationDicoms from '@/assets/icons/navigation-dicom-outline.svg';
import navigationDicomsWhite from '@/assets/icons/navigation-dicom-outline-white.svg';
import navigationCube from '@/assets/icons/navigation-cube-outline.svg';
import navigationCubeWhite from '@/assets/icons/navigation-cube-outline-white.svg';
import navigationPdf from '@/assets/icons/navigation-pdf-outline.svg';
import navigationPdfWhite from '@/assets/icons/navigation-pdf-outline-white.svg';

import { h } from 'vue';
import type { IconAliases } from 'vuetify';

const formusIcons: Partial<IconAliases> = {
    stem: stemImageUrl,
    'stem-light': stemImageHoverUrl,
    cup: cupImageUrl,
    'cup-light': cupImageHoverUrl,
    combined: combinedImageUrl,
    'combined-light': combinedImageHoverUrl,
    'expand-lateral': expandLateral,
    'collapse-lateral': collapseLateral,
    megaphone: megaphone,
    'case-new': caseNew,
    'case-processing': caseProcessing,
    'case-completed': caseCompleted,
    'case-error': caseError,
    'complete-tooltip': completeTooltip,
    'error-tooltip': errorTooltip,
    'info-tooltip': infoTooltip,
    'navigation-settings-outline': navigationSettings,
    'navigation-settings-outline-white': navigationSettingsWhite,
    'navigation-dicoms-outline': navigationDicoms,
    'navigation-dicoms-outline-white': navigationDicomsWhite,
    'navigation-planner-outline': navigationCube,
    'navigation-planner-outline-white': navigationCubeWhite,
    'navigation-plans-outline': navigationPdf,
    'navigation-plans-outline-white': navigationPdfWhite,
};

const f = {
    component: (props: any) => {
        const src = formusIcons[props.icon];
        if (!src) {
            console.error(`Icon not found: ${props.icon}`);
            return null; // Return null to avoid invalid VNode
        }
        // Set size defaults if not provided
        const size = props.size;
        const width = props.width;
        const height = props.height;

        return h('img', {
            src,
            alt: props.icon,
            color: props.color ?? 'primary',
            class: 'v-icon__svg',
            style: {
                width: width ?? size,
                height: height ?? size,
            },
        });
    },
};

export { formusIcons, f };
