<!--
This common shared component is intended as a workaround to display a tooltip
the user can hover over to interact with the content (i.e. click a link).
-->
<template>
    <v-menu
        location="bottom"
        open-on-hover
        allow-overflow
        transition="fade-transition"
        :content-class="contentClasses">
            <slot></slot>
    </v-menu>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

    export interface Props {
        addContentClass?: string;
    }

    const props = defineProps<Props>();

    const defaultContentClass = 'interactive-tooltip bg-white text-on-surface elevation-3';

    const contentClasses = computed(() => (
        props.addContentClass ? defaultContentClass.concat(' ', props.addContentClass) : defaultContentClass
    ));

</script>

<style lang="scss">
</style>