<template>
    <interactive-tooltip v-if="caseError" location="bottom start" activator="parent">
        <v-layout data-testid="case-error-tooltip" class="case-status-tooltip pt-6 pb-6 pl-4 pr-4">
            <span>
                <v-icon icon="f:error-tooltip" size="small" class="case-status-tooltip-icon mr-5" />
            </span>
            <span>
                <h3 class="pb-3" data-testid="case-error-tooltip-heading">Case Failed To Process</h3>
                <p data-testid="case-error-tooltip-message">
                    This case has encountered technical errors. Check your case settings page for
                    more information or <a href="mailto:support@formuslabs.com" target="_blank">Contact Support</a> for help.
                </p>
            </span>
        </v-layout>
    </interactive-tooltip>
</template>

<script setup lang="ts">
    import { defineProps } from 'vue';
    import { type CaseStatus } from '@/api/caseSearch';

    export interface Props {
        caseStatus: CaseStatus;
    }

    const props = defineProps<Props>();

    const caseError = props.caseStatus.failed;
</script>

<style scoped lang="scss">
    .case-status-tooltip {
        display: inline-flex;
        max-width: 550px;
    }

    .case-status-tooltip-icon {
        height: 40px;
        width: 30px;
    }
</style>