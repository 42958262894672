import type { CaseStatus } from '@/api/caseSearch';
import { CaseStatusState } from '@/api/caseSearch';
import { ROUTES } from '@/router';

export function enabledActionsByStatus(status: CaseStatus): ROUTES[] {
    const actions: Record<CaseStatusState, ROUTES[]> = {
        [CaseStatusState.New]: [ROUTES.EDIT_CASE],
        [CaseStatusState.ProcessingCatStack]: [ROUTES.EDIT_CASE],
        [CaseStatusState.Processing]: [ROUTES.EDIT_CASE, ROUTES.CT_SCAN],
        [CaseStatusState.Complete]: [
            ROUTES.EDIT_CASE,
            ROUTES.CT_SCAN,
            ROUTES.PLANNER,
            ROUTES.PLANS,
        ],
        [CaseStatusState.Warning]: [ROUTES.EDIT_CASE, ROUTES.CT_SCAN, ROUTES.PLANNER],
        [CaseStatusState.Unknown]: [],
        [CaseStatusState.Error]: [ROUTES.EDIT_CASE],
    };

    if (status.failed) {
        return [ROUTES.EDIT_CASE];
    }

    const result = actions[status.state];
    if (result !== undefined) {
        return result;
    } else {
        throw new Error(`Invalid case-status-state: ${status}`);
    }
}
