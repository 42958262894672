<template>
    <app-modal width="600" v-model="isVisible">
        <template v-slot:activator="{ props: activatorProps }">
            <!-- Using our app-button-primary does not work, needs to review why -->
<!--            <app-button-primary>-->
<!--                height="40"-->
<!--                color="primary"-->
<!--                variant="outlined"-->
<!--                v-bind="activatorProps"-->
<!--                class="rounded-4"-->
<!--                >-->
<!--                from DICOM files-->
<!--            </app-button-primary>-->
            <v-btn
                height="40"
                color="primary"
                variant="outlined"
                v-bind="activatorProps"
                class="rounded-4"
            >
                from DICOM files
            </v-btn>
        </template>

        <v-card>
            <app-notification
                v-if="showNotification"
                variant="info"
                :animate-to="true"
                title="Study duplicated successfully"
            />

            <app-modal-title class="headline"  @close="isVisible = false"> Duplicate Study</app-modal-title>

            <app-modal-content>
                Create a new study using the existing DICOM files.
                <br />
            </app-modal-content>
            <app-modal-content>
                <v-checkbox dense :disabled="isWorking" v-model="sendEmail" label="Send email" />
                <v-checkbox
                    dense
                    :disabled="isWorking"
                    v-model="sendNotifications"
                    label="Send notifications"
                />
            </app-modal-content>
            <app-modal-actions>
                <v-spacer />
                <app-button-secondary @click="isVisible = false" class="mx-4">
                    Cancel
                </app-button-secondary>
                <app-button-primary @click="onCreate" :loading="isWorking">
                    Create
                </app-button-primary>
            </app-modal-actions>
        </v-card>
    </app-modal>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useAdminCaseStore } from '@/admin/store';
    import type { ApiStudy } from '@/admin/api/study';

    const props = defineProps<{ study: ApiStudy }>();

    const isWorking = ref(false);
    const isVisible = ref(false);

    const sendEmail = ref(false);
    const sendNotifications = ref(false);

    const showNotification = ref(false);

    const store = useAdminCaseStore();

    watch(
        () => isVisible.value,
        (value) => {
            if (!value) {
                sendEmail.value = false;
                sendNotifications.value = false;
                showNotification.value = false;
            }
        },
    );

    const onCreate = async () => {
        try {
            isWorking.value = true;
            await store.duplicateCase(props.study, {
                send_email: sendEmail.value,
                create_notifications: sendNotifications.value,
            });

            showNotification.value = true;

            await store.load();
        } finally {
            isWorking.value = false;
        }
    };
</script>

<style scoped></style>
