<template>
    <div class="group-value" data-testid="native-measurement">
        {{ measurement.format(measurement.value) }}
        <span v-if="hasTooltip">
            <v-icon v-if="hasTooltip" size="25" color="blue" :icon="iconType" />
            <interactive-tooltip
                persistent
                activator="parent"
                location="bottom center"
                offset="26"
                :add-content-class="'pa-5'"
            >
                <span class="measurements-tooltip" v-html="tooltip" />
            </interactive-tooltip>
        </span>
        <div class="group-label">{{ measurement.title }}</div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { MeasurementInfo, MeasurementKey } from '@/planner/components/measurements';

    // Define props for measurement and its key
    const props = defineProps<{
        measurement: MeasurementInfo;
        id: MeasurementKey;
    }>();

    // Define dynamic tooltip condition
    const hasTooltip = computed(() => props.measurement.warning || props.measurement.preCheck);

    // Define optional icon type
    const iconType = computed(() => (props.measurement.warning ? 'mdi-information-variant-circle' : undefined));

    // Tooltip content logic
    const tooltip = computed(() => {
           if (props.measurement.preCheck) {
               return (
                   // TODO update after release notes link is created
                   'This case was not included in the HLD measurement update. See more information' +
                   ' <a ' +
                   'href="https://support.formuslabs.com/hc/en-us/categories/900000199863-Release-Notes-ANZ"' +
                   ' target="_blank"' +
                   ' class="tooltip-link">' +
                   'here</a>.'
               );
           }
           if (props.measurement.warning) {
               return (
                   'Formus has detected a potential issue that may have affected this HLD measurement.' +
                   ' See more information' +
                   ' <a ' +
                   'href="https://support.formuslabs.com/hc/en-us/categories/900000199863-Release-Notes-ANZ"' +
                   ' target="_blank"' +
                   ' class="tooltip-link">' +
                   'here</a> or contact support for assistance.'
               );
           }
        return null;
    });
</script>

<style scoped lang="scss">
    .group-value {
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.009375em;
        line-height: 1.75rem;
        color: rgb(var(--v-theme-white));
    }

    .group-label {
        font-size: 0.75rem;
        letter-spacing: 0.0333333333em;
        line-height: 1.25rem;
        color: rgb(var(--v-theme-on-scene-background))
    }

    .measurements-tooltip {
      width: auto !important;
      max-width: 260px !important;
    }

    :deep(.tooltip-link) {
      color: darkblue;
      text-decoration: underline;
      cursor: pointer;
    }
</style>
